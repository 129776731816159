.simaut-container-title {
    background: url('../../../../public/images/353headerimage.png') center center/cover no-repeat;
    height: 33vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
  }
  
  .simaut-container-main {
  background: whitesmoke;
  height: 235vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  }

  @keyframes rainbow {
    0% { color: red; }
    16.67% { color: orange; }
    33.33% { color: yellow; }
    50% { color: green; }
    66.67% { color: blue; }
    100% { color: violet; }
  }

  .simaut-h1 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 70px;
    max-width: 2000px;
    animation: rainbow 3s infinite linear;
    text-shadow: 
        -3px -3px 0 #000,  
        3px -3px 0 #000,
        -3px 3px 0 #000,
        3px 3px 0 #000;
  }

  .simaut-date-text {
    text-align: left;
    font-size: 25px;
    color: black;
    margin-left: -1300px;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Aligns the text and image at the top */
    max-width: 1200px; /* Adjust based on your design */
    width: 100%; /* Ensure the content takes the full width */
  }
  
  .image-content {
    position: relative;
    flex-shrink: 1; /* Prevents the image from shrinking */
    max-width: 500px; /* Adjust this value based on your design */
  }
  
  .simaut-text {
    position: relative;
    text-align: left;
    top: 0px;
    left: 0px;
    font-size: 20px;
    max-width: 1100px;
    color: black;
    margin-right: 20px;
    flex: 1;
  }
  
  .simaut-image,
  .warning-image {
    position: relative;
    z-index: 10;
    margin-top: -80px;
    width: 100%; /* Makes the image responsive */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures the image covers the container */
  }

  .warning-image {
    position: absolute;
    margin-left: 1099px;
    margin-top: -142px;
    width: 20%;
  }

  .path-following-video {
    width: 800px;
    height: auto;
    margin-left: 150px;
    margin-top: 40px;
    margin-bottom: 40px;
  }