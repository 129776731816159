.featured {
    padding: 4rem;
    background: #fff;
    width: 470px;
    display: flex;
    flex: 1;
    margin: 50 1rem;
    border-radius: 10px;
  }
  
  h1 {
    text-align: center;
    margin-top: 70px;
    margin-bottom: -40px;
    font-size: 66px;
  }
  
  .featured_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .featured_wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .featured_projects {
    margin-bottom: 24px;
  }
  
  .featured_link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .featured_pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .featured_pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    background-color: #000; /*1f98f4 (sky blue) looks good or 1ff44a7e (forest green)*/
    box-sizing: border-box;
  }
  
  .featured_img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .featured_img:hover {
    transform: scale(1.1);
  }
  
  .featured_info {
    padding: 20px 30px 30px;
  }
  
  .featured_text {
    color: #000;
    font-size: 22px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content_blog_container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .featured_projects {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .featured {
      margin-bottom: 2rem;
    }
  }