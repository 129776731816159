.machineshop-container-title {
    background: url('../../../../public/images/lathe_photo.jpg') center center/cover no-repeat;
    height: 33vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.machineshop-container-main {
  background: whitesmoke;
  height: 250vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.machineshop-h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 70px;
  max-width: 2000px;
  color: white;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns the text and image at the top */
  max-width: 1200px; /* Adjust based on your design */
  width: 100%; /* Ensure the content takes the full width */
}
  
.image-content {
  position: relative;
  flex-shrink: 1; /* Prevents the image from shrinking */
  max-width: 500px; /* Adjust this value based on your design */
}

.machineshop-text {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 20px;
  max-width: 550px;
  color: black;
  margin-right: 20px;
  flex: 1;
}

.machineshop-text-bottom {
  font-size: 20px;
  max-width: 1000px;
  top: -80px;
  position: relative;
}

.machineshop-image {
  position: relative;
  z-index: 10;
  top: -100px;
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.machineshop-image-two {
  flex-shrink: 0;
  position: relative;
  z-index: 10;
  top: -140px;
  left: 80px;
  width: 85%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.machineshop-image-three {
  flex-shrink: 0;
  position: relative;
  z-index: 10;
  top: -40px;
  left: 130px;
  width: 85%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.machineshop-image-four {
  position: relative;
  z-index: 10;
  top: -40px;
  left: -40px;
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.link:hover {
  color: #ffc400;
}

.ms-date-text {
  font-size: 25px;
  left: -470px;
  top: 12px;
  position: relative;
  color: black;
  flex: 1;
}