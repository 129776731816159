.footer-container {
    background: url('../../public/images/footer-with-logos.png') center center/cover
    no-repeat;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height: 150px;
    width: 1519px;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    position: relative;
    z-index: 2;
}

.contact-me {
    margin-left: 1200px;
    margin-top: -60px;
    color: #ffffffc9;
    font-size: 20px;
}

.contact-me-email {
    font-size: 17px;
    margin-left: 0px;
    margin-top: 7px;
}

.contact-me-subtext {
    font-size: 16px;
    margin-left: 0px;
}

.thanks {
    margin-left: -600px;
    margin-top: -20px;
    color: #ffffffc9;
    font-size: 27px;
    max-width: 130px;
}

.website-created {
    margin-left: -10px;
    margin-top: -50px;
    color: #ffffffc9;
    font-size: 16px;
    max-width: 180px;
}