.sealevels-container-title {
  background: url('../../../../public/images/lathe_photo.jpg') center center/cover no-repeat;
  height: 33vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.sealevels-container-main {
  background: orange; /* CHANGE THIS */
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.sealevels-h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 70px;
  max-width: 2000px;
  color: white;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns the text and image at the top */
  max-width: 1200px; /* Adjust based on your design */
  width: 100%; /* Ensure the content takes the full width */
}

.image-content {
  position: relative;
  flex-shrink: 1; /* Prevents the image from shrinking */
  max-width: 500px; /* Adjust this value based on your design */
}

.sealevels-text {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 20px;
  max-width: 550px;
  color: black;
  margin-right: 20px;
  flex: 1;
}

.sealevels-image,
.caution-tape {
  position: relative;
  z-index: 10;
  margin-top: -80px;
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.caution-tape {
  margin-top: 0px;
}