.website-container-title {
  background: url('../../../../public/images/website_title_background.png') center center/cover no-repeat;
  height: 33vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.website-h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 80px;
  max-width: 1000px;
  color: white;
}

.website-container-main {
  background: rgba(244, 236, 236, 0.595);
  height: 130vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns the text and image at the top */
  max-width: 1200px; /* Adjust based on your design */
  width: 100%; /* Ensure the content takes the full width */
}

.image-content {
  position: relative;
  flex-shrink: 1; /* Prevents the image from shrinking */
  max-width: 500px; /* Adjust this value based on your design */
}

.website-text {
  font-size: 20px;
  max-width: 550px;
  color: rgb(0, 0, 0);
  margin-bottom: 20px; /* Adds spacing between text blocks */
  position: relative;
  top: -80px;
  left: auto; /* Reset left position */
  margin-right: 20px;
  flex: 1;
}

.bottom-text {
  font-size: 20px;
  max-width: 1100px;
  color: rgb(0, 0, 0);
  position: relative;
  top: 0px;
  left: auto;
  flex: 1;
}

.website-image {
  position: relative;
  top: -80px;
  z-index: 10;
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.date-text5 {
  font-size: 25px;
  left: -650px;
  top: 7px;
  position: relative;
  flex: 1;
}