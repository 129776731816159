.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal-content {
    margin-top: 70px;
    width: 1370px;
    height: 650px;
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 0px;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-title {
    font-size: 46px;
    font-weight: 1500;
    color: black;
    text-align: justify;
    margin-top: 30px;
    margin-left: 57px;
}

/* Text inside the modal */
.modal-text {
    font-size: 20px;
    color: black;
    margin-top: 100px;
    margin-left: 130px;
    max-width: 1100px;
    align-content: left;
}

/* The close button */
.modal-button {
    position: absolute;
    top: 120px;
    right: 120px;
    background-color: white;
    color: black;
    padding: 15px 25px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    z-index: 11;
}

.zoom-button {
    background-color: white;
    color: black;
    padding: 15px 15px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    z-index: 11;
}

.modal-button:hover,
.zoom-button:hover {
    background-color: #e0e0e0;
}

.modal-image,
.im-left, .im-right, .gm-left, .gm-right, .jm-left,
.bubs, .subs, .lubs,
.om-left, .om-right,
.interrupt {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 280px;
  z-index: 10;
  width: 45%;
  object-fit: cover;
}

.om-left {
    width: 75%;
    margin-left: 130px;
}

.om-right {
    width: 75%;
    margin-left: 0px;
}

.im-left {
    width: 75%;
    margin-left: 130px;
}

.im-right {
    width: 130%;
    margin-left: -170px;
}

.gm-left {
    width: 89.8%;
    margin-left: 80px;
}

.jm-left {
    width: 84.5%;
    margin-left: 80px;
}

.gm-right {
    width: 89.3%;
    margin-left: -0px;
}

.bubs {
    width: 60%;
    margin-left: 220px;
}

.subs {
    width: 40%;
    margin-left: 330px;
}

.lubs {
    width: 40%;
    margin-left: 384px;
}

.interrupt {
    width: 61%;
    margin-left: 180px;
}

.content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Aligns the text and image at the top */
    max-width: 1200px; /* Adjust based on your design */
    width: 100%; /* Ensure the content takes the full width */
  }
  
  .image-content {
    position: relative;
    flex-shrink: 1; /* Prevents the image from shrinking */
    max-width: 500px; /* Adjust this value based on your design */
  }

  .test-run-video {
    width: 800px;
    height: auto;
    margin-left: 150px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .power-circuit-video,
  .adjust-demo-video {
    width: 300px;
    height: auto;
    margin-left: 350px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .adjust-demo-video {
    width: 400px;
  }