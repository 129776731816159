.pcbdesign-container-title {
  background: url('../../../../public/images/pcb_title_bkgd.png') center center/cover no-repeat;
  height: 33vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.pcbdesign-container-main {
  background: black;
  height: 315vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.pcbdesign-h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 70px;
  max-width: 2000px;
  color: white;
  text-shadow: 
    -3px -3px 0 #000,  
    3px -3px 0 #000,
    -3px 3px 0 #000,
    3px 3px 0 #000;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns the text and image at the top */
  max-width: 1200px; /* Adjust based on your design */
  width: 100%; /* Ensure the content takes the full width */
}

.image-content {
  position: relative;
  flex-shrink: 1; /* Prevents the image from shrinking */
  max-width: 500px; /* Adjust this value based on your design */
}

.pcbdesign-text {
  position: relative;
  top: -320px;
  left: 20px;
  font-size: 20px;
  max-width: 550px;
  color: white;
  margin-right: 20px;
  flex: 1;
}

.pcbdesign-image {
  position: relative;
  z-index: 10;
  top: -440px;
  left: -120px;
  width: 140%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.pcbdesign-image-two {
  position: relative;
  z-index: 10;
  top: -380px;
  left: 110px;
  width: 78%;
  height: auto;
  object-fit: cover;
}

.pcbdesign-image-three {
  position: relative;
  z-index: 10;
  top: -230px;
  left: 170px;
  width: 90%;
  height: auto;
  object-fit: cover;
}

.pcbdesign-image-four {
  position: relative;
  z-index: 10;
  top: -230px;
  left: -40px;
  width: 76%;
  height: auto;
  object-fit: cover;
}

.pcbdesign-image-five {
  position: relative;
  z-index: 10;
  top: -77px;
  left: -120px;
  width: 120%;
  height: auto;
  object-fit: cover;
}

.pcbdesign-image-six {
  position: relative;
  z-index: 10;
  top: -110px;
  left: -180px;
  width: 160%;
  height: auto;
  object-fit: cover;
}

.date-text {
  font-size: 25px;
  left: -470px;
  top: 12px;
  position: relative;
  color: white;
  flex: 1;
}

.pcbdesign-text-bottom {
  font-size: 20px;
  max-width: 1000px;
  top: -290px;
  color: white;
  position: relative;
}

.pcbdesign-text-bottom-two {
  font-size: 20px;
  max-width: 1000px;
  top: -130px;
  color: white;
  position: relative;
}