.robotcomp-container-title {
  background: 
  linear-gradient(to bottom, rgba(245, 245, 245, 0) 75%, white 100%), /* Adjust the transparency and stop values */
  url('../../../../../public/images/robotTeamPhotoBWnames3.jpg') center center/cover no-repeat;
  height: 110vh;
  width: 100%;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
}

.robotcomp-container-main {
  background: whitesmoke;
  height: 470vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
}

.robotcomp-h1 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 70px;
  max-width: 2000px;
  color: white;
}

svg {
  width: 1500px;
  height: 1500px;
}

.robotcomp-text {
  position: relative;
  text-align: center;
  top: 200px;
  margin-bottom: 150px;
  left: 0px;
  font-size: 20px;
  max-width: 950px;
  color: black;
  margin-right: 20px;
  transition: opacity 0.8s ease;
}
.hidden {
  opacity: 0;
}

.robotcomp-video-container {
  position: relative; /* Ensures that child elements can be positioned relative to this */
  height: 100vh;
  width: 100%;
  margin-top: 250px;
}

.video-cover {
  position: relative;
  background-color: whitesmoke;
  width: 100%;
  margin-top: 72px;
  height: 1vh;
  z-index: 10;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.timeline-button {
  font-size: 18px;
  transition: all 0.35s ease-out;
}

.timeline-button:hover {
  font-size: 25px;
}

.robotcomp-image {
  position: relative;
  z-index: 10;
  top: -80px;
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}