* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Abel', sans-serif; /* Choose between Oswald, Abel, Roboto */
  }
  
  .home,
  .about,
  .personalprojects,
  .workshopcourses,
  .classprojects {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .home {
    background-image: url('../public/images/ubc_image.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .about {
    background: url('../public/images/ubc_image.jpg') center center/cover
    no-repeat;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
  }

  .personalprojects {
    background: url('../public/images/ubc_image.jpg') center center/cover
    no-repeat;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
  }

  .workshopcourses {
    background: url('../public/images/ubc_image.jpg') center center/cover
    no-repeat;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
  }

  .classprojects {
    background: url('../public/images/ubc_image.jpg') center center/cover
    no-repeat;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
  }

  .workshopcourses > h1,
  .personalprojects > h1,
  .about > h1 {
    color: #fff;
    font-size: 65px;
    margin-top: -105px;
    margin-bottom: 55px;
  }

  .classprojects > h1 {
    color: #fff;
    font-size: 65px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  h6 {
    font-weight: 70;
  }