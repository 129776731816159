.motorcontrol-container-title {
  background: url('../../../../public/images/motorcontrol_bkgd.png') center center/cover no-repeat;
  height: 33vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
  object-fit: contain;
  position: relative;
  z-index: 2; 
}

.motorcontrol-container-main {
  background: whitesmoke;
  height: 800vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.motorcontrol-h1 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 70px;
  max-width: 2000px;
  color: white;
}

.motorcontrol-text,
.intro-text {
  position: relative;
  text-align: justify;
  margin-top: 0px;
  left: 0px;
  font-size: 20px;
  max-width: 800px;
  color: black;
  margin-right: 20px;
  flex: 1;
}

.intro-text {
  margin-top: -140px;
}

.motorcontrol-headers {
  position: relative;
  font-size: 23px;
  color: black;
  flex: 1;
  text-align: left;
  margin-top: 0px;
  margin-bottom: -160px;
}

.h4 {
  font-weight: 90;
}

.motorcontrol-image,
.motorcontrol-image-left,
.motorcontrol-image-right,
.mc-image-left3,
.mc-image-right4,
.limage-left5,
.limage-right6,
.bimage-left7,
.bimage-right8 {
  position: relative;
  z-index: 10;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 50%; /* Makes the image responsive */
  max-width: 400px;
  height: auto; /* Maintains aspect ratio */
  object-fit: cover; /* Ensures the image covers the container */
}

.motorcontrol-image-left {
  margin-top: -40px;
  width: 80%;
  left: 280px;
}

.motorcontrol-image-right {
  margin-top: -40px;
  width: 77%;
  right: 180px;
}

.mc-image-left3 {
  margin-top: 40px;
  width: 170%;
  left: 180px;
}

.mc-image-right4 {
  margin-top: 40px;
  width: 120%;
  right: 180px;
}

.limage-left5 {
  width: 140%;
  margin-top: 8px;
  left: 220px;
}

.limage-right6 {
  width: 80%;
  right: 160px;
  margin-top: 10px;
  margin-bottom: 150px;
}

.bimage-left7 {
  width: 110%;
  top: -150px;
  left: 320px;
}

.bimage-right8{
  width: 90%;
  top: -150px;
  right: 260px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns the text and image at the top */
  max-width: 1200px; /* Adjust based on your design */
  width: 100%; /* Ensure the content takes the full width */
}

.image-content {
  position: relative;
  flex-shrink: 1; /* Prevents the image from shrinking */
  max-width: 500px; /* Adjust this value based on your design */
}

.mc-date-text {
  font-size: 25px;
  left: -390px;
  margin-top: 12px;
  position: relative;
  flex: 1;
}

.mc-left-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
}

.mc-right-image {
  position: fixed;
  top: 0;
  right: 0;
  width: 15%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
}